import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="content" >
      <div className="section" style={{
            backgroundImage: "url(/img/topo.svg)",
            backgroundSize: "100%",
            backgroundColor: "#000C29",
            overflow: "visible",
            backgroundRepeat: "no-repeat",
            paddingTop: "2.5em",
            paddingBottom: "5em",
            height: "1000px",
          }}>
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column is-offset-1">
              <h2 style={{
                color: "#A1DE1D",
              }}>Page or resource not found</h2>
              <p>Try going to an existing page in the navigation above.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
